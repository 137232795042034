$(document).ready(function() {
	$('.widget.faq ul li a').click(function() {
		$(this).parent().toggleClass('active');
		$("p", $(this).parent()).slideToggle();
		return false;
	});

	$('.sidebar-nav ul li.submenu.slide > a').click(function() {
        if($("ul", $(this).parent()).is(':hidden')) {
            $("li ul", $(this).parent().parent()).slideUp();
        }

		$("ul", $(this).parent()).slideToggle();
		return false;
	});

	$("#togglenav").click(function() {
		if($("#wrapper").hasClass('toggled')) {
			$("#wrapper").removeClass('toggled');
		} else {
			$("#wrapper").addClass('toggled');
		}

		return false;
	});

	$('.progress-bar-wika .progress-bar').each(function(index) {
		$(this).animate({
			width: $(this).data('value')
		}, 400, 'linear')
	});
});